.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  background-color: rgb(30,31, 65);;
  
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  height: 125px;
  width: 125px; 
  padding: 15px;
  padding-top: 20px;
}

.logoLanding {
  height: 200px;
  width: 200px; 
}

.customIndent {
  padding-left: 1em;
}


.showLink {
  text-decoration: none;
  color: rgb(30,31, 65);
  background-color: rgb(30,31, 65);;
  padding: 7.5px 20px 7.5px 20px;
  border-radius: 100px;
  
}

.regularLink {
  color: rgb(30,31, 65);;
  text-decoration: none;
  padding-bottom: 10px;
}

.addressLink {
  color: rgb(30,31, 65);;
  text-decoration: none;
  font-weight: 500;
}

.odd {
  background-color: #f6f6f6;
}

.scheduleTime {
  font-weight: 600;
}

.endSchedule {
  border-bottom: 1px solid rgb(30,31, 65);;
}

.startSchedule {
  border-top: 1px solid rgb(30,31, 65);;
}

.schedule {
  width: 100vw !important;
}

.map {
  border: none;
  height: 300px;
  width: 100%;
}

.tocmap {
  width: 100%;
  position: relative;
}

.extraPadding {
  padding-bottom: 10px;
}

.extraPadding2 {
  padding-bottom: 15px;
}

.g {
  background-color: #f6f6f6;
}

.addBottom {
  border-bottom: 1px solid rgb(30,31, 65) !important;
}

.pressableBox {
  transition-duration: 0.2s;
}

.pressableBox:active {
  scale: 0.85;
}

.redButton {
  cursor: pointer;
  background-color: rgb(170, 20, 12);
}

.redButton:active {
  cursor: pointer;
  scale: 0.85;
  box-shadow: inset 20px 20px 60px #91110a,
      inset -20px -20px 60px #c4170e;
}

.blueButton {
  cursor: pointer;
  background-color: rgb(8,31,122);
}

.blueButton:active {
  cursor: pointer;
  scale: 0.85;
  box-shadow: inset 20px 20px 60px #071a68,
    inset -20px -20px 60px #09248c
}

.greenButton {
  cursor: pointer;
  background-color: rgb(18,80,8);
}

.greenButton:active {
  cursor: pointer;
  scale: 0.85;
  box-shadow: inset 20px 20px 60px #0f4407,
    inset -20px -20px 60px #155c09;
}

.purpleButton {
  cursor: pointer;
  background-color: rgb(102, 25, 120);
}

.purpleButton:active {
  cursor: pointer;
  scale: 0.85;
  box-shadow: inset 20px 20px 60px #571566,
            inset -20px -20px 60px #751d8a;
}

.paddedPill {
  margin-right: 10px;
}

.w {
  background-color: white !important;
}

.showLink {
  color: white !important;
}


.start {
  border-top: 1px solid gray !important;
  border-radius: 20px 20px 0px 0px;
  font-weight: 800;
}

.final {
  border-radius: 0px 0px 20px 20px;
  margin-bottom: 15px;
}

.noLine {
  border: none !important;
  
}

.largest {
  flex: 12 !important;
  justify-content: flex-start !important;
  padding: 10px;
  padding-right: 10px;
  font-size: 0.75em !important;
  overflow: hidden !important;

}

input[type="checkbox"] {
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  border: 1px solid gray;
  border-radius: 15em;
  overflow: hidden;
}

.odd {
  background-color: rgb(243, 245, 249);
}

.fourth {
  flex: 1 !important;
  min-width: 50px;
}

.third {
  flex: 1 !important;
  min-width: 50px;
}

.two {
  flex: 2 !important;
  min-width: 75px;
}

.inputField {
  min-width: 50px;
  margin: 5px;
  border: none;
  height: 70%;
  border: 0px solid gray;
  border-width: 0px 0px 1px 0px;
}
