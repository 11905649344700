body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.Collapsible {
  border-color: rgb(30,31, 65);
  border: 0px 0px 1px 0px;
  display: flex;
  flex-direction: column;
}

.Collapsible__trigger {
  width: 100%;
  padding: 1rem 0rem 1em 0.5rem;
  color: rgb(30,31, 65);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600 !important;
  border-bottom: 1px solid rgb(30,31, 65);
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
  padding-left: 2rem;
  font-weight: 600;

}

.Collapsible__trigger svg {
  font-size: 30px;
  padding-left: 2rem;
  padding-right: 2rem;
  font-weight: 600;

}

.Collapsible__contentInner {
  width: 100%;
  font-weight: 600;
}
